<template>
	<div class="contanier">
		<div class="filter-container title-border">
			<!-- 关键字搜索 -->
			<div class="filter-item">
				<label class="label">商品名称:</label>
				<el-input v-model="searchKey" style="width: 200px;" placeholder="商品名称"></el-input>
			</div>
			<div class="filter-item">
				<label class="label">商品条码:</label>
				<el-input v-model="searchCode" style="width: 200px;" placeholder="商品编码"></el-input>
			</div>
			<!-- 销售分组 -->
			<div class="filter-item">
				<label class="label">商品分组:</label>
				<el-select v-model="group" style="width: 200px;" placeholder="请选择">
					<el-option label="全部" :value="null"></el-option>
					<el-option
						v-for="(item,index) in groupOptions"
						:key="index"
						:label="item.groupName"
						:value="item.groupId"
					></el-option>
				</el-select>
			</div>
			<!-- button -->
			<div class="filter-item">
				<el-button type="primary" style="margin:0 30px;width:90px;" @click="handleFilter">查询</el-button>
			</div>
		</div>
		<!-- table -->
		<div class="table-container content">
			<el-table
				height="450"
				:data="tableDataList"
				v-loading="loading"
				style="width: 100%"
				ref="compSelectProTable"
				:row-key="getRowKeys"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
				<el-table-column prop="goodsName" label="商品" width="450">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img v-if="scope.row.mainImgUrl" :src="scope.row.mainImgUrl" />
							<div class="right">
								<div class="name">
									<pre
										style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;"
									>{{scope.row.goodsName}}</pre>
								</div>
								<div style="margin-top: 5px;">{{scope.row.goodsCode}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="salePrice" label="价格"></el-table-column>
				<el-table-column prop="specValues" label="规格"></el-table-column>
				<el-table-column prop="stock" label="库存"></el-table-column>
			</el-table>
				<el-checkbox
				style="margin-top:25px;margin-left:13px;float:left;"
				:value="checkedAll"
				@change="selectCurrentAll"
			>当前页全选</el-checkbox>
			<el-pagination
				v-if="page.total"
				style="margin-top:20px;float:right;"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="page.current"
				:page-sizes="[5,10, 15, 20, 25, 30]"
				:page-size="page.size"
				layout="total, sizes, prev, pager, next, jumper"
				:total="page.total"
			></el-pagination>
		</div>
		<div slot="footer" class="dialog-footer">
			已选中
			<span style="color:#F56C6C">{{multipleSelection.length}}</span>
			件
			<el-button class="button" @click="saveSelectProList" style="margin-left:10px;" type="primary">确定</el-button>
		</div>
	</div>
</template>
<script>
import {
	afterSaleGoodsList,
	afterSaleGoodsGroupList
} from "@/api/goods.js";
import config from '@/config/index'

export default {
	name: 'selectProduce',
	props: {
		api: String, //弹框商品列表请求接口名称, 统一加在 api/popGoods.js里
		selectedData: { //需要选中的商品列表
			type: Array,
			default: () => {
				return []
			}
		},
		params: { //自定义请求参数
			type: Array,
			default: () => {
				return []
			}
		},
		isShowCheck: {
			type: Boolean,
			default: () => {
				return true
			}
		},
		isShowSpecValue: {
			type: Boolean,
			default: () => {
				return false
			}
		},
		isCheckMore: {
			type: Boolean,
			default: () => {
				return false
			}
		},
	},
	data () {
		return {
			baseAPI: config.BASE_URL,
			imgUrl: config.IMG_BASE,
			searchKey: '',
			searchCode: '',
			group: null,
			groupOptions: [],
			brand: null,
			brandOptions: [],
			page: {
				total: 0,
				current: 1,
				size: 10
			},
			tableDataList: [],
			selectCount: 0,
			multipleSelection: [],
			checkedShopPro: true,

			loading: false
		}
	},
	created () {
		this.getGroupList();
		this.getSingleList()

	},
	watch: {},
	computed: {
		checkedAll () {
			var val = this.multipleSelection;
			let ableSelectLength = 0 //获取当前页可选的全部页数
			var ids = val.map(item => {
				return item.skuId
			})
			this.tableDataList.forEach(item => {
				if (ids.indexOf(item.skuId) > -1) {
					ableSelectLength++;
				}
			});
			return (ableSelectLength > 0 && this.tableDataList.length == ableSelectLength) ? true : false;
		}
	},
	methods: {
		async getGroupList(){
			try{
				let data = {}
				let result = await afterSaleGoodsGroupList(data);
				if(result.success){
					this.groupOptions = result.data;
				}
			}catch(e){
				//TODO handle the exception
			}
		},
		async getSingleList () {
			this.loading = true;
			try {
				let data = {
					pageNo: this.page.current, // 略过数据
					pageSize: this.page.size, // 取的数据
					goodsCode:this.searchCode,
					goodsName:this.searchKey,
					groupId:this.group,//分组id
				};
				let result = await afterSaleGoodsList(data);
				this.page.total = result.data.total;
				result.data.records.map(item=>{
					item.specValues = item.specValues?item.specValues:'默认规格';
					return item;
				})
				this.tableDataList = result.data.records;

			} catch (error) {
				console.log(error);
			} finally {
				if (!this.isReady) {
					this.selectedData.map(item => {
						this.$refs['compSelectProTable'].toggleRowSelection(item, true);
					})
					this.isReady = true;
				}
				this.loading = false;
			}
		},
		//外部全选当前页
		selectCurrentAll (e) {
			this.$refs['compSelectProTable'].toggleAllSelection();
			console.log(e);
		},
		//当前页全选
		handleSelectionChange (val) {
			this.multipleSelection = val;

		},
		// 切换显示条数
		handleSizeChange (val) {
			this.page.size = val;
			this.getSingleList();
		},
		// 翻页
		handleCurrentChange (val) {
			this.page.current = val;
			this.getSingleList()
		},
		// selectInit(row, id) {
		// 	return (row.Remark && row.Remark != '') ? false:true
		// },
		clearSelection () {
			this.$nextTick(() => {
				this.$refs.compSelectProTable.clearSelection();
			});
		},
		getRowKeys (row) {
			return row.skuId;
		},
		handleFilter () {
			// this.multipleSelection=[];
			console.log(this.group);
			this.page.current = 1;
			this.getSingleList();
		},
		//关闭弹框，以及曝光选择的列表
		saveSelectProList () {
			if (!this.multipleSelection.length) {
				this.$message({
					showClose: true,
					message: '请选择商品',
					type: 'info'
				})
				return
			}
			this.multipleSelection.map(item=>{
				if(!item.goodsCount){
					item.goodsCount=1;
				}
				return item;
			})
			this.$emit('getSelectList', this.multipleSelection)
		}

	}
}
</script>
<style lang="less" scoped>
.contanier {
	position: relative;
	overflow: hidden;
}

.content {
	overflow: hidden;
}

.dialog-footer {
	margin-top: 20px;
	text-align: center;

	.button {
		width: 150px;
	}
}

.dialog-name-content {
	margin: 10px 0;
	display: flex;
	flex-direction: row;

	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		margin-right: 10px;
	}
}
.noImgIcon {
	width: 60px;
	height: 60px;
}
</style>
