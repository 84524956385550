import { render, staticRenderFns } from "./vipCreateOrder.vue?vue&type=template&id=29ef5ea6&scoped=true&"
import script from "./vipCreateOrder.vue?vue&type=script&lang=js&"
export * from "./vipCreateOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29ef5ea6",
  null
  
)

export default component.exports